import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import AlertState from "../Context/Alert/AlertState";
import AuthState from "../Context/Auth/AuthState";
import RqState from "../Context/Request/RqState";
import { default as route, default as routes } from "../route";
import PrivateRoute from "./components/PrivateRoute";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });

    const routeComponents = route.map(({ path }, key) => {
      // return <Route exact path={path} component={component} key={key} />
      return <Route exact path={path} key={key} />;
    });

    return (
      <ScrollToTop>
        <AlertState>
          <AuthState>
            <ToastProvider>
            <RqState>
              <Switch>
                {menu}
                <PrivateRoute path="/" component={AdminLayout} />
                {routeComponents}
              </Switch>
            </RqState>
            </ToastProvider>
          </AuthState>
        </AlertState>
      </ScrollToTop>
    );
  }
}

export default withTranslation()(App);
